<template>
  <div class="finanziamenti" ref="loans">
    <div v-if="isLoadingPage">
      <Loader class="no-bg" />
    </div>
    <div v-else class="container-fluid w-auto m-4">
      <div class="row d-flex justify-content-between align-items-center mb-4">
        <div class="col">
          <h4>Finanziamenti</h4>
        </div>
        <div class="col d-flex justify-content-end">
            <button class="btn btn-fin" @click="togglePopup('upsertLoan')">
                Aggiungi finanziamento
            </button>
        </div>
      </div>

      <div class="table-wrapper my-4">
        <b-table
          id="table"
          ref="table"
          :items="loans"
          :fields="fields"
          :busy.sync="isBusy"
          hover
          show-empty
          @row-clicked="goToDetails"
        >
          <template #cell(paidDate)="row">
            {{ row.item.paidDate ? toFormattedDate(row.item.paidDate) : '' }}
          </template>
          <template #cell(bank)="row">
            <div class="d-flex align-items-center">
              <img v-if="getLogo(row.item.bank.id)" :src="getLogo(row.item.bank.id)" class="bank-logo md mr-2">
              <div :class="`text-bank-variant-${row.item.bank.colour ? row.item.bank.colour : 0}`" class="font-weight-600">
                {{ row.item.bank.alias ? row.item.bank.alias : row.item.bank.name }}
              </div>
            </div>
          </template>
          <template #cell(extinctionDate)="row">
            {{ row.item.extinctionDate ? toFormattedDate(row.item.extinctionDate) : '' }}
          </template>
          <template #cell(type)="row">
            {{ row.item.type | movement | capitalizeFirstLetter }}
          </template>
          <template #cell(importo)="row">
            <CellContent
              :value="row.item.importo ? parseFloat(row.item.importo) : null"
              class="d-flex justify-content-end m-0 p-0 border-0"
            />
          </template>
          <template #cell(residuo)="row">
            <CellContent
              :value="row.item.residuo ? parseFloat(row.item.residuo) : null"
              class="d-flex justify-content-end m-0 p-0 border-0"
            />
          </template>
          <template #cell(actions)="row">
            <b-dropdown
              size="sm"
              id="dropdown-1"
              right
              variant="link"
              toggle-class="text-decoration-none"
            >
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item
                @click="
                  $router.push({ name: 'loanDetails', params: { id: row.item.id, edit: true } })
                "
                >Modifica</b-dropdown-item
              >
              <b-dropdown-item
                @click="
                  togglePopup('deleteLoan');
                  selectedRow = row.item;
                "
                class="red"
                >Elimina</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template #empty>
            <div class="d-flex justify-content-center align-items-center py-2">
              Nessun risultato trovato
            </div>
          </template>
        </b-table>
      </div>
    </div>

    <Popup ref="upsertLoan" class="xl">
      <template #fullContent>
        <UpsertLoan :banks="banks" :companyId="companyId" @save="onUpsertedLoan" :instalmentToEdit="instalmentToEdit" :instalmentToDelete="instalmentToDelete" @openEditInstalmentPopup="openEditInstalmentPopup" @openDeleteInstalmentPopup="openDeleteInstalmentPopup" @loanSaved="onLoanSaved"/>
      </template>
    </Popup>

    <Popup ref="deleteLoan" class="md">
      <template #title> Sei sicuro di voler eliminare? </template>
      <template #text>
        Il finanziamento <b>{{ selectedRow.label }}</b> di {{ selectedRow.bank }} e tutti i movimenti ad esso associati verranno eliminati definitivamente.
      </template>
      <template #button>
        <custom-button
          :isLoading="loading"
          label="Elimina"
          class="w-100 mt-4"
          @click.prevent.native="onDelete"
        />
      </template>
    </Popup>

    <!-- Special Popups -->
    <Popup ref="deleteInstalment" class="md" :errorMessage="errorMessage">
      <template #title> Sei sicuro di voler eliminare? </template>
      <template #text>
        La rata n. {{instalment.nRata}} con scadenza {{toFormattedDate(instalment.date)}}
        {{instalment.amount ? ('di ' + parseFloat(instalment.amount).toFixed(2) + ' €') : ''}}
        verrà rimossa
      </template>
      <template #button>
          <custom-button
          label="Rimuovi"
          class="w-100 mt-4"
          @click.prevent.native="deleteInstalment(instalment)"
          />
      </template>
    </Popup>

    <Popup ref="editInstalment" class="md" :errorMessage="errorMessage">
      <template #fullContent>
          <edit-instalment :instalment="instalment" @save="saveInstalment" key="editInstalmentInLoans" ref="editInstalmentInLoans"/>
      </template>
    </Popup>

    <Snackbar :text="snackbarText" ref="snackbarLoans" class="move-up"/>
  </div>
</template>

<script>
import bankLogoDefault from '@/assets/images/bank-logo.svg';
import capitalizeFirstLetter from '@/filters/capitalizeFirstLetter';
import formatMovementTypeFilter from '@/filters/formatMovementType';
import { toFormattedDate } from '@/helpers/dates.js';
import { BDropdown, BDropdownItem, BTable } from 'bootstrap-vue';

import Button from '@/views/components/Button.vue';
import CellContent from '@/views/components/Table/CellContent.vue';
import EditInstalment from '@/views/components/Forms/Loans/EditInstalment.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Popup from '@/views/components/Popup.vue';
import UpsertLoan from '@/views/components/Forms/Loans/UpsertLoan.vue';
import Snackbar from '@/views/components/Snackbar.vue';

export default {
  name: 'loans',
  components: {
    'b-table': BTable,
    'b-dropdown': BDropdown,
    'b-dropdown-item': BDropdownItem,
    'custom-button': Button,
    CellContent,
    EditInstalment,
    Loader,
    Popup,
    UpsertLoan,
    Snackbar,
  },
  filters: {
    movement: formatMovementTypeFilter,
    capitalizeFirstLetter: capitalizeFirstLetter,
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      banks: [],
      fields: [
        { key: 'label', label: 'Finanziamento', sortable: false },
        { key: 'bank', label: 'Banca', sortable: false },
        { key: 'importo', label: 'Importo orignario', sortable: false, class: 'text-right' },
        { key: 'paidDate', label: 'Data erogazione', sortable: true },
        { key: 'residuo', label: 'Debito residuo', sortable: false, class: 'text-right' },
        { key: 'extinctionDate', label: 'Data estinzione', sortable: true },
        { key: 'actions', label: '', sortable: false },
      ],
      loans: [],
      companyId: this.$oauth2.me.companies[0].id || null,

      errorMessage: null,
      showPopup: true,
      snackbarText: '',
      loading: false,
      isLoadingPage: true,
      selectedRow: null,

      // Popup
      instalment: null,
      instalmentToEdit: null,
      instalmentToDelete: null,

      // Table
      isBusy: false,
    };
  },
  computed: {
    bankId: function () {
      return this.$route.query.bankId;
    },
  },
  watch: {
    $route: function () {
      this.getLoans();
    },
  },
  methods: {
    getCompanyInfo () {
      this.$api
        .getCompany(this.companyId)
        .then((res) => {
          this.banks = res.data.banks;
          this.selectedBank = this.searchInBanks(this.bankId);
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    getLoans () {
      this.isBusy = true;
      this.$api
        .getListLoans(this.companyId)
        .then((res) => {
          this.$log.debug(res.data);
          this.loans = res.data.finanziamenti || [];
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.isBusy = false;
          this.isLoadingPage = false;
        });
    },
    getLogo (bankId) {
      const bank = this.banks.find(bank => bank.id === bankId);
      return (bank && bank.logo) ? bank.logo : bankLogoDefault;
    },
    goToDetails (item) {
      this.$router.push({ name: 'loanDetails', params: { id: item.id } });
    },
    onDelete () {
      this.loading = true;
      this.$api
        .deleteLoan(this.selectedRow.id)
        .then(() => {
          this.togglePopup('deleteLoan');
          this.snackbarText = 'Finanziamento eliminato correttamente';
          this.$refs.snackbarLoans.openSnackbar();
          this.getLoans();
        })
        .catch((e) => {
          this.$log.debug(e);
          this.errorMessage = 'Qualcosa è andato storto. Riprova tra qualche minuto';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onEdit () {
      this.getLoans();
      this.togglePopup('editLoan');
      this.snackbarText = 'Modifiche salvate';
      this.$refs.snackbarLoans.openSnackbar();
    },
    onLoanSaved () {
      this.getLoans();
      this.togglePopup('upsertLoan');
      this.snackbarText = 'Finanziamento aggiunto correttamente';
      this.$refs.snackbarLoans.openSnackbar();
    },
    onUpsertedLoan () {
      this.getLoans();
      this.togglePopup('upsertLoan');
      this.snackbarText = 'Finanziamento aggiunto';
      this.$refs.snackbarLoans.openSnackbar();
    },
    searchInBanks () {
      return this.banks.find((bank) => bank.id === this.bankId);
    },
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
    openEditInstalmentPopup (item) {
      this.instalment = item;
      this.togglePopup('editInstalment');
    },
    openDeleteInstalmentPopup (item) {
      this.instalment = item;
      this.togglePopup('deleteInstalment');
    },
    saveInstalment (item) {
      this.snackbarText = 'Rata aggiornata';
      this.instalmentToEdit = JSON.parse(JSON.stringify(item));
      this.$refs.snackbarLoans.openSnackbar();
      this.togglePopup('editInstalment');
    },
    deleteInstalment (item) {
      this.snackbarText = 'Rata eliminata';
      this.instalmentToDelete = JSON.parse(JSON.stringify(item));
      this.$refs.snackbarLoans.openSnackbar();
      this.togglePopup('deleteInstalment');
    },
  },
  mounted () {
    this.getCompanyInfo();
    this.getLoans();
  },
};
</script>

<style lang="scss">
.finanziamenti {
  background: $primary-lighter;

  .move-up {
    z-index: 20000000;
  }
  // row settings
  .dropdown {
    .dropdown-menu {
        border-radius: 10px;
      padding: 0;
      overflow: hidden;
      box-shadow: $box-shadow;
      &:focus {
          outline: none;
      }
      .red > * {
        color: $danger !important;
      }
      .dropdown-item {
          color: $primary;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 14px;
        &:focus {
            outline: none;
        }
        &:active {
            background: $primary-light;
        }
      }
    }
  }

  .dropdown-toggle {
    padding: 0;
    margin: 0;
    &::after {
        display: none;
    }
  }

  // selected bank box
  .box {
      border: none;
    // background: $primary-light;
    border-radius: 10px;
    padding: 15px;
    transition: all 0.5s;
  }
  .table-wrapper {
      border-radius: 10px;
    box-shadow: $box-shadow;
    // overflow: hidden;
    height: min-content;
  }

  table {
    background: white;
    text-overflow: ellipsis;
    font-size: 16px;
    margin: 0;
    border-radius: 10px;
    th {
      border: none;
      text-transform: uppercase;
      font-size: 14px;
      color: rgba($primary, 0.75);
      font-weight: 600;
    }
    thead {
      border: none !important;
      background: $fin-white;
      border-radius: 10px;
      th {
        // white-space: nowrap;
        padding: 23px 15px;
      }
    }
    td:nth-last-of-type(1) {
      min-width: 50px;
    }
  }
}
</style>
