<template>
  <div class="loan-details">
    <div v-if="isLoadingPage">
      <Loader class="bg-fin" />
    </div>
    <div v-else class="container-fluid w-auto m-4">
      <div class="row d-flex justify-content-between align-items-center mb-4">
        <div class="col">
          <h4>
            <i class="fas fa-angle-left pr-2" style="cursor: pointer;" @click="$router.go(-1)"></i>
            Dettagli finanziamento
          </h4>
        </div>
        <div class="col d-flex justify-content-end">
            <button class="btn btn-fin-red mr-4" @click="toggleDeleteLoan">
              Elimina
            </button>
            <button class="btn btn-fin" @click="toggleEditLoan">
              Modifica finanziamento
            </button>
        </div>
      </div>
      <div class="row my-5 w-100 m-0">
        <div class="col-12 box" :class="rowColourLighter">
          <div class="row d-flex justify-content-between p-0 m-0">
            <div class="col d-flex flex-column box-sm">
                <label class="text-uppercase text-nowrap" for="">Finanziamento</label>
                {{ name }}
            </div>
            <div class="col ml-3 d-flex flex-column box-sm">
                <label class="text-uppercase text-nowrap" for="">Banca</label>
                <div :class="`text-bank-variant-${colour ? colour : 0}`" class="font-weight-600">
                  {{ bank.alias }}
                </div>
            </div>
            <div class="col ml-3 d-flex flex-column box-sm text-right">
                <label class="text-uppercase text-nowrap" for="">Importo originario</label>
                <CellContent
                  :value="amount ? parseFloat(amount) : null"
                  class="d-flex justify-content-end m-0 p-0 border-0"
                />
            </div>
            <div class="col ml-3 d-flex flex-column box-sm">
                <label class="text-uppercase  text-nowrap" for="">Data erogazione</label>
                {{ lendingDate ? toFormattedDate(lendingDate) : '' }}
            </div>
            <div v-if="!isManualLoan" class="col ml-3 d-flex flex-column box-sm  text-right">
                <label class="text-uppercase text-nowrap" for="">Debito residuo</label>
                <CellContent
                  :value="remainingAmount ? parseFloat(remainingAmount) : null"
                  class="d-flex justify-content-end m-0 p-0 border-0"
                />
            </div>
            <div class="col ml-3 d-flex flex-column box-sm">
                <label class="text-uppercase text-nowrap" for="">Data estinzione</label>
                {{ extinctionDate ? toFormattedDate(extinctionDate) : null }}
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-12">
          <InstalmentsTable :instalments="instalmentsDetails" :loanId="id" :isManual="isManualLoan" @openSnackbar="openSnackbar" @reloadData="reloadData"/>
        </div>
      </div>
    </div>

    <Popup ref="warningPopup" class="md">
        <template #title>Attenzione!</template>
        <template #text>
          Tutti gli impegni associati al piano verranno eliminati e sostituiti con quelli aggiornati.
        </template>
        <template #button>
            <custom-button
            label="Ho capito"
            class="w-100 mt-4"
            @click.prevent.native="togglePopup('warningPopup'); togglePopup('editLoan');"
            />
        </template>
    </Popup>

    <Popup ref="editLoan" class="xl">
      <template #fullContent>
        <UpsertLoan :banks="banks" :loan="loan" :page="tipoPiano" :companyId="companyId" @save="onEditedLoan" :instalmentToEdit="instalmentToEdit" :instalmentToDelete="instalmentToDelete" @openEditInstalmentPopup="openEditInstalmentPopup" @openDeleteInstalmentPopup="openDeleteInstalmentPopup"/>
      </template>
    </Popup>

    <Popup ref="deleteLoan" class="md">
      <template #title> Sei sicuro di voler eliminare? </template>
      <template #text>
        Il finanziamento {{loan.label}} di {{loan.bank}} e tutti i movimenti ad esso associati verranno eliminati definitivamente.
      </template>
      <template #button>
          <custom-button
          :isLoading="loading"
          label="Elimina"
          class="w-100 mt-4"
          @click.prevent.native="onDelete"
          />
      </template>
    </Popup>

    <Popup ref="deleteLoanSuccess" class="md" hideCloseButton>
      <template #title>Finanziamento eliminato correttamente</template>
      <template #text>
        Il finanziamento e tutti i movimenti ad esso associati sonos stati rimossi.<br/>
      </template>
      <template #button>
          <custom-button
          label="Torna ai finanziamenti"
          class="w-100 mt-4"
          @click.prevent.native="$router.push({ name: 'loans' })"
          />
      </template>
    </Popup>

    <!-- Special Popups -->
    <Popup ref="deleteInstalment" class="md">
      <template #title> Sei sicuro di voler eliminare? </template>
      <template #text>
        La rata n. {{instalment.nRata}} con scadenza {{toFormattedDate(instalment.date)}}
        {{instalment.amount ? ('di ' + parseFloat(instalment.amount).toFixed(2) + ' €') : ''}}
        verrà rimossa
      </template>
      <template #button>
          <custom-button
          :isLoading="loading"
          label="Rimuovi"
          class="w-100 mt-4"
          @click.prevent.native="deleteInstalment(instalment)"
          />
      </template>
    </Popup>

    <Popup ref="editInstalment" class="md">
      <template #fullContent>
          <edit-instalment :instalment="instalment" @save="saveInstalment" key="editInstalmentInLoanDetails" ref="editInstalmentInLoanDetails"/>
      </template>
    </Popup>

    <Snackbar :text="snackbarText" ref="snackbarLoansDetails" class="up"/>
  </div>
</template>

<script>
import { toFormattedDate } from '@/helpers/dates.js';

import Button from '@/views/components/Button.vue';
import CellContent from '@/views/components/Table/CellContent.vue';
import EditInstalment from '@/views/components/Forms/Loans/EditInstalment.vue';
import InstalmentsTable from '@/views/components/InstalmentsTable.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Popup from '@/views/components/Popup.vue';
import UpsertLoan from '@/views/components/Forms/Loans/UpsertLoan.vue';
import Snackbar from '@/views/components/Snackbar.vue';

export default {
  props: {
    edit: Boolean,
  },
  components: {
    'custom-button': Button,
    CellContent,
    EditInstalment,
    InstalmentsTable,
    Loader,
    Popup,
    UpsertLoan,
    Snackbar,
  },
  watch: {
    $route () {
      this.id = this.$route.params.id;
      this.getLoanById(this.id);
    },
  },
  data () {
    return {
      errorMessage: null,
      isLoadingPage: true,
      loading: false,
      snackbarText: '',
      banks: [],
      companyId: this.$oauth2.me.companies[0].id || null,

      loan: null,
      id: this.$route.params.id,
      tipoPiano: null,

      instalmentToEdit: null,
      instalmentToDelete: null,

      name: null,
      bank: null,
      colour: null,
      lendingDate: null,
      extinctionDate: null,
      amount: null,
      remainingAmount: null,
      instalments: [],
      plan: {},

      rowColour: null,
      rowColourLighter: null,
      // Table
      instalmentsDetails: [],
    };
  },
  computed: {
    isManualLoan () {
      return this.loan?.piano?.tipoPianoId !== 1 && this.loan?.piano?.tipoPianoId !== 2 && this.loan?.piano?.tipoPianoId !== 3;
    },
  },
  methods: {
    getCompanyInfo () {
      this.$api
        .getCompany(this.companyId)
        .then((res) => {
          this.banks = res.data.banks;
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    getLoanById (id) {
      this.$api.fetchLoan(id)
        .then((res) => {
          this.$log.debug(res.data);
          this.loan = res.data;
          this.name = this.loan.label;
          this.colour = this.loan.bankColour;
          this.bank = this.loan.bank;
          this.amount = this.loan.importo;
          this.lendingDate = this.loan.paidDate;
          this.instalmentsDetails = this.loan.rate;
          this.plan = this.loan.piano;
          this.extinctionDate = this.loan.extinctionDate;
          this.remainingAmount = this.loan.residuo;
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = 'Qualcosa è andato storto. Controlla i dati inseriti';
        })
        .finally(() => {
          this.updateColours();
          this.isLoadingPage = false;
          if (this.edit) {
            this.tipoPiano = this.loan.piano.tipoPianoId;
            this.togglePopup('editLoan');
          }
        });
    },
    searchBankById () {
      return this.banks.find((bank) => bank.id === this.bankId);
    },
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
    togglePopup (ref) {
      return this.$refs[ref].togglePopup();
    },
    updateColours () {
      this.rowColour = this.colour
        ? `bank-variant-${this.colour}-light`
        : 'primary-light';
      this.rowColourLighter = this.colour
        ? `bank-variant-${this.colour}-lighter`
        : 'primary-lighter';
    },
    openSnackbar (text) {
      this.snackbarText = text;
      this.$refs.snackbarLoansDetails.openSnackbar();
    },
    reloadData () {
      return this.getLoanById(this.id);
    },
    onEditedLoan () {
      this.$refs.editLoan.close();
      this.isLoadingPage = true;
      this.snackbarText = 'Finanziamento aggiornato corretamente';
      this.$refs.snackbarLoansDetails.openSnackbar();
    },
    onDelete () {
      this.loading = true;
      this.$api.deleteLoan(this.loan.id)
        .then(() => {
          this.togglePopup('deleteLoan');
          this.togglePopup('deleteLoanSuccess');
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = 'Qualcosa è andato storto. Riprova tra qualche minuto';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleEditLoan () {
      const paidInstalments = this.instalmentsDetails.filter(obj => obj.isPaid);
      if (paidInstalments.length > 0) {
        this.togglePopup('warningPopup');
      } else {
        this.togglePopup('editLoan');
      }
    },
    toggleDeleteLoan () {
      this.togglePopup('deleteLoan');
    },
    openEditInstalmentPopup (item) {
      this.instalment = item;
      this.togglePopup('editInstalment');
    },
    openDeleteInstalmentPopup (item) {
      this.instalment = item;
      this.togglePopup('deleteInstalment');
    },
    saveInstalment (item) {
      this.snackbarText = 'Rata aggiornata';
      this.instalmentToEdit = JSON.parse(JSON.stringify(item));
      this.$refs.snackbarLoansDetails.openSnackbar();
      this.togglePopup('editInstalment');
    },
    deleteInstalment (item) {
      this.snackbarText = 'Rata eliminata';
      this.instalmentToDelete = JSON.parse(JSON.stringify(item));
      this.$refs.snackbarLoansDetails.openSnackbar();
      this.$refs.deleteInstalment.close();
    },
  },
  mounted () {
    this.getCompanyInfo();
    this.getLoanById(this.id);
  },
};
</script>

<style lang="scss">
.loan-details {
  background: $primary-lighter;
  .up {
    z-index: 50;
  }

  .box {
    border: none;
    //background: $primary-light;
    border-radius: 10px;
    padding: 15px;
    transition: all 0.5s;
  }

  .box-sm {
    max-width: fit-content;
    padding: 6px 10px;
    border-radius: 10px;
    transition: all 0.5s;
    color: #292E4D;
    filter: brightness(102%);
    label {
      font-weight: 700;
        font-size: 14px;
        opacity: 0.9;
    }
  }

  .table-wrapper {
    border-radius: 10px;
    box-shadow: $box-shadow;
    // overflow: hidden;
    height: min-content;
  }

  table {
    background: white;
    text-overflow: ellipsis;
    font-size: 16px;
    margin: 0;
    border-radius: 10px;
    th {
      border: none;
      text-transform: uppercase;
      font-size: 14px;
      color: rgba($primary, 0.75);
      font-weight: 600;
    }
    thead {
      border: none !important;
      background: $fin-white;
      border-radius: 10px;
      th {
        padding: 23px 15px;
      }
    }
  }

    // row settings
  .dropdown {
    button {
      // no caret
      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      border-radius: 10px;
      padding: 0;
      overflow: hidden;
      box-shadow: $box-shadow;
      &:focus {
        outline: none;
      }
      .red > * {
        color: $danger !important;
      }
      .dropdown-item {
        color: $primary;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 14px;
        &:focus {
          outline: none;
        }
        &:active {
          background: $primary-light;
        }
      }
    }
  }
}
</style>
